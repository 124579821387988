import './../css/tailwind.css';
import './../css/nprogress.css';

import { createInertiaApp } from '@inertiajs/react';

import AppContainer from './Components/AppContainer';
import VaporInstance from 'laravel-vapor';
import axios from 'axios';
import colors from '@/utils/colors';
import { createRoot } from 'react-dom/client';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import capitalize from 'lodash/capitalize';

declare global {
    interface Window {
        Vapor: any;
        Intercom: any;
        axios: any; // should be title-cased `Axios`
    }
}

(window as any).axios = axios;
(window as any).axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
(window as any).Vapor = VaporInstance;
(window as any).Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Medialake';
const clientName = appName.includes('-') ? appName.split('-')[0] : appName;
const app = `${capitalize(clientName)} | MedialakeAI`;

createInertiaApp({
    title: (title: string) => (title ? `${title} - ${app}` : app),
    resolve: (name: string) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ App, props, el }) {
        // @ts-ignore
        window.__ = function (key: string) {
            // @ts-ignore
            return props.initialPage?.props?.language[key] ?? key;
        };

        return createRoot(el).render(
            <AppContainer {...props.initialPage}>
                <App {...props} />
            </AppContainer>
        );
    },
    progress: {
        color: colors.primary['600'],
        delay: 250, // The delay after which the progress bar will appear during navigation, in milliseconds.
        includeCSS: true, // Whether to include the default NProgress styles.
        showSpinner: true // Whether the NProgress spinner will be shown.
    }
});
