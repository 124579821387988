import withAnalytics from '@/Components/Analytics/WithAnalytics';
import DefaultErrorUI from '@/Components/DefaultErrorUI';
import { AnalyticsContext } from '@/Hooks/useAnalytics';
import { AppContext } from '@/Hooks/useAppContext';
import useRememberPrevPage from '@/Hooks/useRememberPrevPage';
import { InertiaSharedProps } from '@/Interfaces/types';
import UserInterface from '@/Interfaces/UserInterface';
import { ConfigProvider } from '@/Providers/ConfigProvider';
import FeatureFlagProvider from '@/Providers/FeatureFlagProvider';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';

const queryClient = new QueryClient();

type WithAnalyticsProps = {
    user: UserInterface;
    hasAnalytics: boolean;
    initAnalytics: (user: UserInterface) => void;
    pageview: () => void;
    track: (category: string, action: string, label: string | null, value?: number | null) => void;
    children: React.ReactNode;
    props: InertiaSharedProps<unknown>;
};

function AppContainer(props: WithAnalyticsProps) {
    const INTERCOM_ENABLED = props?.props?.support?.INTERCOM_ENABLED ?? false;
    const { hasAnalytics, initAnalytics, pageview, track, children } = props;

    const user = props?.props?.auth?.user;

    const prevPage = useRememberPrevPage();

    useEffect(() => {
        forceApplyDarkTheme();
        setupAnalytics(user);
        initializeChonkyDefaults().then(() => console.info('Initialized Chonky defaults!'));
    }, []);

    useEffect(() => {
        function setupIntercom() {
            if (!INTERCOM_ENABLED) {
                console.warn('Intercom disabled!');
                return;
            }

            if (!user) {
                console.info('Skipping intercom: Missing user.');
                return;
            }

            console.info('Booting intercom...');

            window.Intercom('boot', {
                app: window.INTERCOM_APP_ID,
                user: user.id,
                name: user.name,
                email: user.email,
                created_at: user.created_at ? Math.floor(new Date(user.created_at).getTime() / 1000) : undefined,
                hide_default_launcher: true
            });

            console.info('Intercom booted!');
        }

        setupIntercom();
        // Other setups...

        return () => {
            window.Intercom('shutdown');
        };
    }, [INTERCOM_ENABLED, user]);

    function forceApplyDarkTheme(): void {
        localStorage.theme = 'dark';
    }

    function setupAnalytics(user?: UserInterface): void {
        if (!user) {
            console.info('Skipping analytics: Missing user.');
            return;
        }

        if (!user?.id) {
            console.warn('Could not set up analytics: Missing user ID.');
            return;
        }

        initAnalytics(user);
        console.info('Set up analytics!');
    }

    function getRollbarConfig(user: UserInterface): any {
        const config = {
            enabled: !(window as any).location.host.includes('localhost'),
            accessToken: '97a9eec0c2494a77a60a6c32f28598db',
            captureIp: 'anonymize',
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: (window as any).location.hostname,
                client: {
                    javascript: {
                        code_version: '1.0.0',
                        source_map_enabled: true
                    }
                }
            }
        };

        if (user && user?.id) {
            (config.payload as any).person = {
                id: user.id,
                username: user.name || '',
                email: user.email || ''
            };
        }

        return config;
    }

    async function initializeChonkyDefaults() {
        const setChonkyDefaults = (await import('chonky')).setChonkyDefaults;
        const ChonkyIconFA = (await import('chonky-icon-fontawesome')).ChonkyIconFA;
        setChonkyDefaults({ iconComponent: ChonkyIconFA as any });
    }

    const rollbarConfig = getRollbarConfig(user);

    return (
        <RollbarProvider config={rollbarConfig}>
            <QueryClientProvider client={queryClient}>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}

                {/* @ts-ignore prop type definition error: https://github.com/rollbar/rollbar-react/issues/87 */}
                <ErrorBoundary fallbackUI={DefaultErrorUI}>
                    <AnalyticsContext.Provider
                        value={{
                            hasAnalytics,
                            initAnalytics,
                            pageview,
                            track
                        }}>
                        <AppContext.Provider
                            value={{
                                prevPage: prevPage
                            }}>
                            {children}
                        </AppContext.Provider>
                    </AnalyticsContext.Provider>
                </ErrorBoundary>
            </QueryClientProvider>
        </RollbarProvider>
    );
}

//! TODO: Refactor this. Remove the HOC, replace with hooks
export default withAnalytics(AppContainer);
